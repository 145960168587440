export const Tracks = [
    {
        "url": "/audio/PissShiversNewTeenageWasteoids.mp3",
        "title": "Teenage Wasteoids",
    },
    {
        "url": "/audio/PissShiversGhostintheGraveyardUnmastered.mp3",
        "title": "Ghost in the Graveyard"
    },
    {
        "url": "/audio/PissShiversNewAlienSong.mp3",
        "title": "Alien Song"
    },
    {
        "url": "/audio/PissShiversNewGospelSong.mp3",
        "title": "Gospel Song"
    },
    {
        "url": "/audio/PissShiversNewBannanaBoat.mp3",
        "title": "Bannana Boat"
    },
    {
        "url": "/audio/PissShiversNew68FUp.mp3",
        "title": "68 Fuck Up"
    },
    {
        "url": "/audio/PissShiversSummerWineNew.mp3",
        "title": "Summer Wine"
    },
    {
        "url": "/audio/PissShiversNEWULTRAGLIDE.mp3",
        "title": "Ultraglide"
    }
]