export const Assets = [
    {
        url: "/json/schlong-dog-data.json",
        name: "schlong-dog-data"
    },
    {
        url: "/marker-font/marker.png",
        name: "marker.png"
    },
    {
        url: "/marker-font/marker.fnt",
        name: "marker.fnt"
    },
]