export const Assets = [
    {
        url: "/images/toilet.jpg",
        name: "toilet"
    },
    {
        url: "/images/particle.png",
        name: "pissParticle"
    },
    {
        url: "/audio/water-pour.mp3",
        name: "pissAudio"
    }
];