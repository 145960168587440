export const HelpList = [
    {
        text: "Play a Piss Shivers song",
        icon: "play.png"
    },

    {
        text: "Drink some Beer to fill the Piss-O-Meter (don't FUCK UP and drink too much or you'll be up to your eyeballs in piss)",
        icon: "beer.png"
    },

    {
        text: "Use the TOILET to drain the Piss-O-Meter",
        icon: "mouse.png"
    },

    {
        text: "Follow Piss Shivers on Spotify or Apply Music",
        icon: "spotify.png"
    }
]